<template>
  <div class="admin-login white--text d-flex flex-column justify-center align-center pt-15">
    <img class="mb-5" src="@/assets/img/logo.png" alt="logo" width="115px">
    <img class="mb-md-2 mb-12" src="@/assets/img/text-vault.svg" width="115px">
    <div class="login-block d-flex flex-column justify-center align-center w-full">
      <div class="rem-12 mb-11">登入您的管理者帳號</div>
      <textField dark label="請輸入帳號" :inputText.sync="account"></textField>
      <btn :buttonText="'登入'" width="100%" color="linear-gradient(90deg, #FFFFFF 1.67%, #C5C5C5 98.33%)" class="black--text" @clickBtn="login()"></btn>
    </div>
  </div>
</template>
<script>
import textField from '@/components/textField.vue'
import btn from '@/components/btn.vue'
import base from '@/mixin/base'
export default {
  mixins: [base],
  data() {
    return {
      account: ''
    }
  },
  components:{
    textField,
    btn
  },
  methods: {
    async login(){
      if (window.ethereum) {
        await this.connectMetamask()
        let result = await this.$store.dispatch('login', {
          account: this.account,
          address: window.ethereum.selectedAddress
        })

        await this.$store.dispatch('getUserInfo')

        if (result.status === 200) {
          this.$router.push({name: 'Home'})
        }
      } else {
        window.addEventListener('ethereum#initialized', this.connectMetamask, {
          once: true,
        });

        // If the event is not dispatched by the end of the timeout,
        // the user probably doesn't have MetaMask installed.
        setTimeout(this.connectMetamask, 2000); // 3 seconds
      }
      this.$forceUpdate()
    },
  },
  created() {
    if (this.$store.state.token) {
      this.$router.push({name: 'Home'})
    }
  }
}
</script>
<style lang="scss">
.admin-login{
  min-height: 100vh;
  background: radial-gradient(72.26% 119.85% at 50% 36.14%, #535353 0%, #000000 100%);
  .login-block{
    max-width: 330px;
    margin-bottom: 60px;
  }
}
</style>