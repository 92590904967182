<template>
  <div class="rate-setting-history">
    <v-container class="pa-5">
      <div class="rem-6 font-w-4 lightPrimary2--text mb-6 d-md-flex justify-space-between align-center d-none">
        <v-icon @click="$router.go(-1)" color="lightPrimary2">mdi-chevron-left</v-icon>
        <v-spacer></v-spacer>
        利率參數設定
        <v-spacer></v-spacer>
      </div>

      <div class="deposit-card d-flex justify-space-between align-center py-5 pr-3 pl-4 mb-7">
        <div class="d-flex align-center">
          <img class="mr-2" :src="`${require(`@/assets/img/icon-logo-${$route.params.depositToken}.svg`)}`" :alt="$route.params.depositToken">
          <div class="font-share-tech font-w-4">
            <span class="rem-13 rem-sm-16 lightPrimary2--text mr-1">{{ $route.params.depositToken.toUpperCase() }}</span>
            <span class="rem-3 rem-sm-4 lightGrey1--text">/{{ $route.params.interestToken.toUpperCase() }}</span>
          </div>
        </div>
        <v-btn
          class="bg-primary-radial-gradient border-radius-3 darkGrey--text font-w-4 px-2"
          height="24"
          @click.stop="$router.push({name: 'RateSettingEdit', params: {interestToken: $route.params.interestToken, depositToken: $route.params.depositToken}})"
        >
          立即調整
        </v-btn>
      </div>

      <div class="rem-6 font-w-4 lightGrey2--text mb-2">EGT/USDT 利率調整紀錄</div>
      <v-card>
        <v-row class="rem-2 font-w-4 lightGrey2--text text-center" no-gutters>
          <v-col class="py-4" cols="9">利率調整</v-col>
          <v-col class="py-4" cols="3">編輯者</v-col>
        </v-row>
        <v-row
          no-gutters
          class="font-w-4 lightGrey2--text text-center"
          v-for="(item, i) in history"
          :key="i"
        >
          <v-col class="rem-0 py-3 d-flex flex-wrap justify-center" cols="9">
            <div class="ml-2" style="margin-bottom: 2px;" v-for="month in 12" :key="`month${month}`">
              <span class="mr-1">{{ month > 9 ? month : `0${month}` }}</span>
              <div class="rate-input d-inline-block rounded w-100">
                {{ (item[monthMap[month]] * 100).toFixed(1) }}% <span style="font-size: 6px !important;">UT</span>
              </div>
            </div>
          </v-col>
          <v-col class="rem-2 py-3 px-2 d-flex flex-column justify-center align-center break-all" cols="3">
            <div class="mb-1">{{ timestampToDate(item.setting_time * 1000) }}</div>
            <div>{{ item.address }}</div>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import base from '@/mixin/base'
export default {
  mixins: [base],
  data(){
    return {
      history: [],
    }
  },
  async mounted(){
    let result = await this.$store.dispatch('getRateHistory')
    if (result.status === 200) {
      this.history = result.data.sort((a, b) => b.setting_time - a.setting_time)
    }
  }
}
</script>
<style lang="scss">
.rate-setting-history {
  @include dai_vuetify_sm_min {
    max-width: 420px;
    background: radial-gradient(188.38% 188.38% at 50% -88.38%, rgba(99, 99, 99, 0.8) 0%, rgba(71, 71, 71, 0.8) 40.12%, rgba(49, 49, 49, 0.8) 100%);
    border-radius: 11px;
    margin: 40px auto;
  }

  .deposit-card {
    background: rgba(49, 49, 49, 0.8);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(3px);
    border-radius: 5px;
    border: 1px solid #5D5D5D;
  }

  .row {
    border-bottom: 1px solid #EBEBEB;
    .rate-input {
      background: #F3F3F3;
      padding: 2px 10px;
      min-width: 52px;
    }
  }
}
</style>