<template>
  <div class="analyze-token">
    <v-container class="py-5">
      <div class="rem-6 font-w-4 lightPrimary2--text mb-6 text-center d-md-block d-none">
        {{ ($route.params.depositToken).toUpperCase() }}/{{ ($route.params.interestToken).toUpperCase() }} 戰情分析
      </div>

      <v-card color="#4F4F4F" elevation="0" class="mx-sm-2 py-2 px-3 mb-5 date-select-wrap white--text d-flex justify-space-between align-center">
        <div class="d-flex align-center">
          <v-menu
            ref="startMenu"
            v-model="startMenu"
            close-on-content-click
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                prepend-icon="mdi-calendar-month"
                color="lightPrimary2"
                dark
                readonly
                solo
                flat
                hide-details
                style="width: 130px;"
                background-color="transparent"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
              min="2022-01-01"
              @change="saveStart"
            ></v-date-picker>
          </v-menu>

          <span class="ml-n3 mr-3">—</span>

          <v-menu
            ref="endMenu"
            v-model="endMenu"
            close-on-content-click
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                prepend-icon="mdi-calendar-month"
                color="lightPrimary2"
                dark
                readonly
                solo
                flat
                hide-details
                style="width: 130px;"
                background-color="transparent"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
              :min="startDate"
              @change="saveEnd"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="d-flex align-center lightPrimary2--text can-click" @click="exportTokenBalance()">
          匯出
          <v-icon color="lightPrimary2">mdi-menu-down</v-icon>
        </div>
      </v-card>

      <v-tabs v-model="currTab" background-color="transparent" class="px-2">
        <v-tabs-slider color="lightPrimary2" class="mx-auto mt-n2" style="width: 18px"></v-tabs-slider>
        <v-tab
          v-for="tab in tabItems"
          :key="`tab${tab.value}`"
          :class="tab.value === currTab ? 'lightPrimary2--text' : 'lightGrey2--text'"
        >
          {{ tab.text }}
        </v-tab>

        <v-tab-item
          v-for="tab in tabItems"
          :key="`tabItem${tab.value}`"
          class="analyze-wrap"
        >
          <div class="analyze-card d-flex justify-space-between align-center py-3 pr-3 pl-4 mt-3 mb-5">
            <div class="d-flex align-center">
              <img class="mr-2" :src="`${require(`@/assets/img/icon-logo-${$route.params.depositToken}.svg`)}`" :alt="$route.params.depositToken">
              <div class="font-share-tech font-w-4">
                <span class="rem-13 rem-sm-16 lightPrimary2--text mr-1">{{ $route.params.depositToken.toUpperCase() }}</span>
                <span class="rem-3 rem-sm-4 lightGrey1--text">/{{ $route.params.interestToken.toUpperCase() }}</span>
              </div>
            </div>
            <div>
              <div class="lightPrimary2--text">{{ (totalAmount[tab.value] || 0).toLocaleString() }} {{ $route.params.depositToken.toUpperCase() }}</div>
              <div class="lightGrey2--text">≈ {{ (totalAmount[tab.value] * price[$route.params.depositToken] || 0).toLocaleString() }} {{ $route.params.interestToken.toUpperCase() }}</div>
            </div>
          </div>

          <!-- chart -->
          <div class="token-chart rounded py-2 px-0 lightGrey1 mb-4"
          >
            <div :id="`token-chart-${tab.value}`" data="chart" style="height: 190px;"></div>
          </div>

          <!-- daily list -->
          <div class="rem-10 font-w-4 lightGrey2--text mb-2">{{ (tab.value > 1 ? $route.params.interestToken : $route.params.depositToken).toUpperCase() }} {{ (tab.text).slice(0, 2) }}明細</div>

          <dataTable
            :data="currData[tab.value]"
            :column="detailColumn"
            class="white analyze-table lightGrey2--text"
            rowCanClick
            @clickRow="toDetails"
          >
            <template v-slot:action="{item}">
              <div>{{ (item.total_balance).toLocaleString() }} {{ ($route.params.depositToken).toUpperCase() }}</div>
              <div>≈ {{ (item.total_balance * price[$route.params.depositToken]).toLocaleString() }} {{ ($route.params.interestToken).toUpperCase() }}</div>
            </template>
          </dataTable>
        </v-tab-item>
      </v-tabs>
    </v-container>
  </div>
</template>
<script>
import base from '@/mixin/base'
import dataTable from '@/components/dataTable.vue'
const echarts = require('echarts')
export default {
  name: "Authority-Token",
  mixins: [base],
  data(){
    return {
      startMenu: null,
      endMenu: null,
      startDate: null,
      endDate: null,
      myChart: {},
    }
  },
  components: {
    dataTable
  },
  watch: {
    '$store.state.now_width': {
      handler: async function () {
        if (this.myChart[this.currTab]) {
          this.myChart[this.currTab].resize()
        }
      },
    },
    currTab: {
      handler: async function(newVal) {
        window.setTimeout(async () => {
          if (!this.myChart[newVal]) {
            await this.initChart(newVal)
          }
          await this.updateChart(newVal)
        }, 1000)
      }
    },
    startDate: {
      handler: async function() {
        await this.getTokenDetailsAndPlot()
      }
    },
    endDate: {
      handler: async function() {
        await this.getTokenDetailsAndPlot()
      }
    },
  },
  computed: {
    detailColumn() {
      return [
        {text: '入帳日期', value: 'date', grid: 3, class: "text-center"},
        {text: '人數', value: 'user_amount', grid: 2, class: "text-center"},
        {text: '平均匯率', value: 'avg_rate', grid: 3, class: "text-center"},
        {text: '數量', value: '', grid: 4, class: "text-center"},
      ]
    },
  },
  methods: {
    saveStart(date) {
      this.$refs.startMenu.save(date)
    },
    saveEnd(date) {
      this.$refs.endMenu.save(date)
    },
    async getTokenDetailsAndPlot() {
      await this.getTokenDetails()
      
      if (!this.myChart[this.currTab]) {
        await this.initChart(this.currTab)
      }
      await this.updateChart(this.currTab)
    },
    initChart(tab){
      this.myChart[tab] = echarts.init(
        document.getElementById(`token-chart-${tab}`),
      )

      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '5%',
          containLabel: true
        },
        xAxis: [
          {
            data: [],
            axisTick: {
              alignWithLabel: true
            },
            color: '#313131',
          }
        ],
        yAxis: [
          {
            type: 'value',
            color: '#313131',
          }
        ],
        series: []
      }

      this.myChart[tab].setOption(option)
    },
    updateChart(tab){
      let option = {}
      option.series = [
        {
          name: (tab > 1 ? this.$route.params.interestToken : this.$route.params.depositToken).toUpperCase(),
          type: 'bar',
          data: this.currData[tab].map(item => item.total_balance),
          showSymbol: false,
          animationEasing: 'linear',
          animationDuration: 1000,
          color: '#313131',
        },
      ]

      option.xAxis = [
        {
          data: this.currData[tab].map(item => this.timestampToDate(item.date * (60 * 60 * 24) * 1000)),
          axisTick: {
            alignWithLabel: true
          },
          color: '#313131',
        }
      ]
      
      this.myChart[tab].setOption(option)
    },
    toDetails(item) {
      this.$router.push({
        name: 'Analyze-Token-Details',
        params: {
          interestToken: this.$route.params.interestToken, 
          depositToken: this.$route.params.depositToken,
          tab: this.tabItems[this.currTab],
          totalAmount: this.totalAmount[this.currTab],
          data: item
        }
      })
    },
    exportTokenBalance() {
      const startTime = Date.parse(this.startDate) / 1000 / (60 * 60 * 24)
      const endTime = Date.parse(this.endDate) / 1000 / (60 * 60 * 24)

      window.open(`${this.$store.state.backendUrl}/analyze${this.$store.state.backendVersion}/download_chose_days_token_detial?token=${this.$route.params.depositToken}&start=${startTime}&end=${endTime}`)
    }
  },
  async mounted(){
    await this.updateDepositTokenPrice()

    if (this.$route.query.start) {
      this.startDate = this.timestampToDate(this.$route.query.start * (60 * 60 * 24) * 1000)
    } else {
      this.startDate = this.timestampToFirstDayofMonth(Date.now())
    }

    if (this.$route.query.end) {
      this.endDate = this.timestampToDate(this.$route.query.end * (60 * 60 * 24) * 1000)
    } else {
      this.endDate = this.timestampToDate(Date.now())
    }

    await this.getTokenDetailsAndPlot()
  }
}
</script>