<template>
  <v-form class="claim-egt-wrap my-15" ref="claimForm" lazy-validation>
    <div class="rem-6">現有EGT數量</div>
    <v-text-field v-model="currBalance" class="py-0" readonly>
      <template #append>EGT</template>
    </v-text-field>

    <div class="rem-6">領取EGT數量</div>
    <v-text-field
      v-model="amount"
      class="py-0 mb-5"
      type="number"
      :rules="TokenAmountRules"
    >
      <template #append>EGT</template>
    </v-text-field>

    <btn class="mx-auto" buttonText="確認領取" color="success" width="140px" @clickBtn="claim()" />
  </v-form>
</template>

<script>
import btn from '@/components/btn.vue'
import base from '@/mixin/base'
export default {
  mixins: [base],
  components: {
    btn
  },
  props: {
    currBalance: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      amount: null,
    }
  },
  methods: {
    claim () {
      if (!this.$refs.claimForm.validate()) {
        return
      }
      this.$emit('claim', parseInt(this.amount))
    }
  }
}
</script>
