<template>
  <div class="analyze-details">
    <v-container class="py-5">
      <div class="rem-6 font-w-4 lightPrimary2--text mb-6 text-center d-md-block d-none">
        {{ ($route.params.depositToken).toUpperCase() }}/{{ ($route.params.interestToken).toUpperCase() }} {{ $route.params.tab.text.slice(0, 2) }}分析
      </div>

      <v-text-field
        v-model="search"
        placeholder="輸入地址或編號搜尋"
        solo
        dark
        background-color="#151515"
        class="mb-6 search-input"
        hide-details
      >
        <template v-slot:append>
          <v-icon color="lightGrey2">mdi-magnify</v-icon>
        </template>
      </v-text-field>

      <div class="analyze-wrap">
        <div class="analyze-card d-flex justify-space-between align-center py-3 pr-3 pl-4 mt-3 mb-5">
          <div class="d-flex align-center">
            <img class="mr-2" :src="`${require(`@/assets/img/icon-logo-${$route.params.depositToken}.svg`)}`" :alt="$route.params.depositToken">
            <div class="font-share-tech font-w-4">
              <span class="rem-13 rem-sm-16 lightPrimary2--text mr-1">{{ $route.params.depositToken.toUpperCase() }}</span>
              <span class="rem-3 rem-sm-4 lightGrey1--text">/{{ $route.params.interestToken.toUpperCase() }}</span>
            </div>
          </div>
          <div>
            <div class="lightPrimary2--text">{{ ($route.params.totalAmount || 0).toLocaleString() }} {{ $route.params.depositToken.toUpperCase() }}</div>
            <div class="lightGrey2--text">≈ {{ ($route.params.totalAmount * price[$route.params.depositToken] || 0).toLocaleString() }} {{ $route.params.interestToken.toUpperCase() }}</div>
          </div>
        </div>

        <!-- daily list -->
        <div class="font-w-4 lightGrey2--text mb-2 d-flex justify-space-between align-center">
          <div>
            <span class="rem-12">{{ timestampToDate($route.params.data.date * (60 * 60 * 24) * 1000) }}</span> {{ ($route.params.tab.value > 1 ? $route.params.interestToken : $route.params.depositToken).toUpperCase() }} {{ ($route.params.tab.text).slice(0, 2) }}詳情
          </div>
          <div class="d-flex align-center lightPrimary2--text can-click" @click="exportTokenDetail()">
            匯出
            <v-icon color="lightPrimary2">mdi-menu-down</v-icon>
          </div>
        </div>

        <dataTable
          :data="filterList"
          :column="detailColumn"
          class="white analyze-table lightGrey2--text"
        >
          <template v-slot:action="{item}">
            <div>{{ (item.amount).toLocaleString() }} {{ ($route.params.depositToken).toUpperCase() }}</div>
            <div>≈ {{ (item.amount * price[$route.params.depositToken]).toLocaleString() }} {{ ($route.params.interestToken).toUpperCase() }}</div>
          </template>
        </dataTable>
      </div>
    </v-container>
  </div>
</template>
<script>
import base from '@/mixin/base'
import dataTable from '@/components/dataTable.vue'
export default {
  name: "Authority-Token-Details",
  mixins: [base],
  data(){
    return {
      search: '',
    }
  },
  components: {
    dataTable
  },
  computed: {
    detailColumn() {
      return [
        {text: '編號', value: 'order_num', grid: 2, class: "text-center"},
        {text: '地址', value: 'shortFrom', grid: 4, class: "text-center"},
        {text: '匯率', value: 'rate', grid: 2, class: "text-center"},
        {text: '數量', value: '', grid: 4, class: "text-center"},
      ]
    },
    filterList() {
      if (this.search) {
        return this.$route.params.data.orders.filter(item => item.order_num.toString() === this.search || item.from.includes(this.search))
      } else {
        return this.$route.params.data.orders
      }
    },
  },
  methods: {
    exportTokenDetail() {
      window.open(`${this.$store.state.backendUrl}/analyze${this.$store.state.backendVersion}/download_daily_token_detial?token=${this.$route.params.depositToken}&day=${this.$route.params.data.date}`)
    }
  },
  async created() {
    if (!this.$route.params.tab) {
      this.$router.push({
        name: 'Analyze-Token',
        params: {
          interestToken: this.$route.params.interestToken, 
          depositToken: this.$route.params.depositToken,
        }
      })
    } else {
      this.$route.params.data.orders.forEach(item => {
        item.shortFrom = this.convertShortAddress(item.from)
      })
    }
  },
  async mounted(){
    await this.updateDepositTokenPrice()
  }
}
</script>