<template>
  <section class="claim-egt-index py-md-4 px-md-14 pa-5 grey--text">
    <v-row>
      <v-col cols="12" lg="6">
        <div class="font-w-7 grey--text d-flex justify-space-between align-end mb-md-9 mb-3">
          <div class="d-md-block d-none">
            <div class="rem-8" style="color: #EAC9C9;">Collection record</div>
            <div class="rem-24" style="color: #CC232A;">EGT領取紀錄</div>
          </div>
          <div class="d-none d-md-block" :style="{width: $store.state.nowWidth >= 960 ? '280px' : '100%'}">
            <v-text-field
              v-model="search"
              class="mb-3 grey--text"
              background-color="lightGrey1"
              solo
              flat
              dark
              hide-details
              append-icon="mdi-magnify"
              placeholder="輸入帳號搜尋"
            ></v-text-field>
          </div>
        </div>

        <claimEgtBlock class="d-block d-lg-none" :curr-balance="currBalance" @claim="claimEgt" />

        <dataTable :data="filterList" :column="column" />
      </v-col>
      <v-col class="d-none d-lg-block">
        <div class="d-md-block d-none font-w-7">
          <div class="rem-8" style="color: #EAC9C9;">Select User Permissions</div>
          <div class="rem-24" style="color: #CC232A;">領取EGT幣</div>
        </div>
        <claimEgtBlock :curr-balance="currBalance" @claim="claimEgt" />
      </v-col>
    </v-row>
  </section>
</template>
<script>
import dataTable from '@/components/dataTable.vue'
import claimEgtBlock from '@/components/claimEgtBlock.vue'
import base from '@/mixin/base'
export default {
  mixins: [base],
  data(){
    return {
      claimHistoryList: [],
      search: '',
      currBalance: 0,
      timer: null,
    }
  },
  components: {
    dataTable,
    claimEgtBlock
  },
  computed: {
    filterList() {
      if (this.search) {
        return this.claimHistoryList.filter(item => item.manager.includes(this.search))
      }
      return this.claimHistoryList
    },
    column() {
      return [
        {text: '管理者', value: 'manager', grid: 4, gridMd: 4, class: "text-center pre-wrap break-all"},
        {text: '數量', value: 'amount', grid: 3, gridMd: 3, class: "text-center d-flex justify-center align-center"},
        {text: '時間戳記', value: 'createTime', grid: 5, gridMd: 5, class: "text-center d-flex justify-center align-center"}
      ]
    }
  },
  methods: {
    async claimEgt (amount) {
      if (amount > this.currBalance) {
        this.$toasted.error('餘額不足')
        return
      }
      if (!this.$store.state.chainId) {
        this.$toasted.error('請切換到幣安智能鏈')
        return
      }
      const oldBalance = this.currBalance
      let result = await this.$vault.claimToken('egt', amount)
      if (result.txHash){
        this.$store.commit('updateLoading', {isShow: true, text: '領取中，請稍後'})
        this.timer = window.setInterval(async () => {
          await this.getVaultBalance()
          if (oldBalance > this.currBalance) {
            window.clearInterval(this.timer)
          
            result = await this.$store.dispatch('claimEgt', {
              address: this.$store.state.account,
              amount: amount,
              tx: result.txHash
            })
            if (result.status !== 200) {
              this.$toasted.error('領取失敗')
              this.$store.commit('updateLoading', {isShow: false, text: ''})
              return
            }

            this.$toasted.show('領取成功')
            await this.getEgtClaimHistory()
            this.$store.commit('updateLoading', {isShow: false, text: ''})
            this.$refs.claimForm.reset()
          }
        }, 1000)
      } else if (result.code === 4001){
        this.$toasted.error('使用者拒絕連線')
      }
    },
    async getEgtClaimHistory () {
      let result = await this.$store.dispatch('getEgtClaimHistory')
      try {
        if (result.status === 200) {
          this.claimHistoryList = result.data.map(item => ({
            ...item,
            manager: `${item.name}\n${item.account}`,
            amount: `${(item.amount).toLocaleString()} EGT`,
            createTime: this.timestampToTime(item.create_time * 1000)
          }))
          this.claimHistoryList.sort((a, b) => b.create_time - a.create_time)
        }
      } catch(error) {
        console.log('error', error)
      }
    },
    async getVaultBalance () {
      this.currBalance = await this.$egt.getBalance(this.$store.state.vaultAddress)
    }
  },
  async mounted(){
    await this.getEgtClaimHistory()
    await this.getVaultBalance()
  }
}
</script>
<style lang="scss">
.claim-egt-index {
  @include dai_vuetify_md {
    min-height: calc(100vh - 60px);
    background: #F5F4F4;
  }
}
</style>
