<template>
  <div class="data-table">
    <!-- header -->
    <v-row no-gutters class="pa-3">
      <v-col :class="col.class" :cols="col.grid" :md="col.gridMd" v-for="col in column" :key="`header-${col.text}`">
        {{ col.text }}
      </v-col>
    </v-row>
    <!-- item -->
    <v-row no-gutters class="pa-3" :class="{'can-click': rowCanClick}" v-for="item in data" :key="item.id" @click="clickRow(item)">
      <v-col :class="col.class" :cols="col.grid" :md="col.gridMd" v-for="col in column" :key="`item-${col.text}`">
        <template v-if="col.value === 'date'">
          {{ timestampToDate(item[col.value] * (60 * 60 * 24) * 1000) }}
        </template>
        <template v-else-if="col.value">
          <div :class="{'text-decoration-underline can-click': col.canCopy}" @click="col.canCopy ? copyText(item[col.value]) : undefined">
            {{ item[col.value] }}
            <img v-if="col.canCopy" class="ml-1" src="@/assets/img/icon-copy.svg" alt="icon-copy">
          </div>
        </template>
        <slot v-else name="action" :item="item"></slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import base from '@/mixin/base'
export default {
  mixins: [base],
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    column: {
      type: Array,
      default: () => {
        return []
      }
    },
    rowCanClick: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickRow(item) {
      if (this.rowCanClick) {
        this.$emit('clickRow', item)
      }
    }
  }
}
</script>

<style lang="scss">
.data-table:not(.analyze-table) {
  @include dai_vuetify_md_min {
    // desktop
    .row {
      border: #D8C1BB 1px solid;
      margin-bottom: 4px;
    }
  }

  @include dai_vuetify_md {
    // mobile
    .row:not(:first-child):nth-child(2n-1) {
      background: #FCFCFC;
    }
    .row:nth-child(2n) {
      background-color: white;
    }
  }
}
</style>